.quote_image {
  /* position: absolute; */
  /* top: 0;
  right: 0;
  z-index: 1; */
}

.quote_text {
  position: absolute;
  top: 0;
  /* width: 430.15px;
  height: 282px;
  left: 942.18px;
  top: 116px; */

  font-family: Meddon;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 74px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.015em;
}
