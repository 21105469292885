.notch {
  clip-path: polygon(
    /* top left */ 0% 25%,
    /* top left */ 25% 0%,
    /* top right */ 75% 0%,
    /* top right */ 100% 25%,
    /* bottom right */ 100% 100%,
    /* bottom right */ 100% 100%,
    /* bottom left */ 0% 100%,
    /* bottom left */ 0 100%
  );
}

.ui.header.meddon,
.ui.card > .content > .header.meddon {
  font-family: Meddon;
  font-style: normal;
  font-weight: normal;
}

.ui.header.meddon {
  align-items: flex-end;
  display: flex;
  font-size: 35px;
  line-height: 74px;
  letter-spacing: -0.015em;
}
