.quote_image {
  position: absolute;
}

.quote_text2 {
  position: absolute;
  top: 0;
  /* width: 430.15px;
  height: 282px;
  left: 942.18px;
  top: 116px; */

  font-family: Meddon;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 54px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.015em;
  color: #cdb86d;
}

.diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: red;
  position: relative;
  top: -50px;
}
.diamond:after {
  content: "";
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: red;
}

.service_box_a,
.service_box_b {
  /* bring your own prefixes */
  display: inline-block;
  background: #e5e5e5;
  transform: rotate(45deg);
  margin: 5em;
}
.service_box_a {
  width: 13em;
  height: 13em;
  left: 9em;
  top: 15em;
  margin: 7em;
}

.service_box_b {
  width: 17em;
  height: 17em;
  left: 44em;
  top: 1em;
  padding-bottom: 50px;
}
