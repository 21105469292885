body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Meddon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Display");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display");

@import url("https://fonts.googleapis.com/css2?family=Caramel");

.ui.header.headerStyle,
.ui.menu.menuStyle {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: normal;
}
.ui.header.headerStyle {
  font-size: 64px;
  line-height: 150%;
  letter-spacing: 0.03em;
}
